export const landingDescription = "Fullstack Web and App developer based in California.";

export const aboutDescription = [
    "Thanks for visiting my website! My name is Ben and I’m a student pursuing software development with a specialty in Web and App development.\n",
    "I always enjoyed working on projects and solving problems, whether if it’s fixing up my car or picking up a new coding language. Getting under the hood and figuring out how things work led me to pursue software development.\n",
    "I have over 4 years of coding experience, using industry standards such as React, Express and other Javascript frameworks. Fast forward to today, I’m currently leading a team to create a ",
    "#social network web application",
    " while constantly finding new projects to enrich my knowledge and experience.\n",
    "Here are the technologies I’ve been working with recently:"
];


//"I’m currently a student that is pursuing software development who specializes in Web and App development. I have 4 years of coding experience, and  I’m currently a student that is pursuing software development who specializes in Web and App development. I have 4 years of coding experience, and etc etc etc.";

export const skills = ["Javascript", "Node.js", "MongoDB", "HTML", "React.js", "PHP", "CSS", "Express"];